import React from 'react'

import { News, NewsRow } from '@/components/common/news'
import { SerializedStyles } from '@emotion/react'
import { NewsRowPage } from './news-row-page'

type Props = {
  newsList: News[]
  className?: string
  newsListCss?: SerializedStyles
  maxLength?: number
  isPage?: boolean
}

export const NewsList = ({
  newsList,
  className,
  newsListCss,
  maxLength,
  isPage = false
}: Props): JSX.Element => {
  if (maxLength) {
    newsList = newsList.slice(0, maxLength--)
  }
  const Component = isPage ? NewsRowPage : NewsRow
  return (
    <ul className={className}>
      {newsList.map((news, index) => (
        <Component
          key={index}
          news={news}
          isLink={!!news.url}
          css={newsListCss}
        />
      ))}
    </ul>
  )
}
