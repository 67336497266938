import React from 'react'
import { css } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { Anchor } from '@/components/common/anchor'

export type News = {
  date: string
  text: string
  url: string
}

export type NewsRowPageProps = {
  news: News
  isLink?: boolean
  className?: string
}

export const NewsRowPage = ({
  news,
  isLink = true,
  className
}: NewsRowPageProps): JSX.Element => {
  const Wrapper = isLink ? Anchor : 'span'
  return (
    <li css={cssNewsRow} className={className}>
      <Wrapper css={cssNewsAnchor} href={news.url}>
        <span css={cssNewsDate}>{news.date}</span>
        <p css={cssNewsText}>{news.text}</p>
      </Wrapper>
    </li>
  )
}

const cssNewsRow = css`
  padding: 10px 0 24px;
  margin-top: 24px;
  color: ${color.black1};
  border-bottom: dotted 1px ${color.gray1};
  ${mq.pc} {
    padding: 10px 0 32px;
    margin-top: 32px;
  }
`

const cssNewsDate = css`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.2px;
  ${mq.pc} {
    font-size: 14px;
    font-weight: 400;
  }
`

const cssNewsText = css`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.9;
  letter-spacing: 1.4px;
  word-break: break-word;
  ${mq.pc} {
    margin: 0 0 0 45px;
    font-weight: 600;
  }
`

const cssNewsAnchor = css`
  ${mq.pc} {
    display: flex;
    align-items: baseline;
  }
`
